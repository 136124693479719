import React, {Component} from 'react'
import Slider from 'react-slick';
import style from './masthead.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Background from 'shared/background/';
import Icon from 'components/icon';
import Link from 'shared/link';

class Masthead extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSlide: 1,
			totalSlides: props.data.length,
		};
		this.leftArrowClick = this.leftArrowClick.bind(this);
		this.rightArrowClick = this.rightArrowClick.bind(this);
	}	

	leftArrowClick() {
		this.slider.slickPrev();
	}

	rightArrowClick() {
		this.slider.slickNext();
	}

	render() {
		const { headline, subheadline, intro } = this.props;
		const settings = {
			dots: true,
			dotsClass: style.pagination,
			customPaging: function(i) {
				return (
					<a>
						<Icon icon="diamond" foreground="#ffffff" />
					</a>
				);
			},
			arrows: false,
			autoPlay: false,
			ref: slider => (this.slider = slider),
			beforeChange: (current, next) => this.setState({currentSlide: next+1}),
		}
		return (
			<div className={style.mastheadWrapper}>
				<Slider {...settings}>
					{this.props.data.map((slide, index) => (
						<div key={index} className={style.container}>
							<Background className={style.background} image={slide.image}>
								<div className="grid-container">
									<div className="grid-x grid-margin-x">
										<div className={[style.textContainer, "cell small-10 medium-11"].join(' ')}>
											<div className={style.title}>{slide.title}</div>
											<div className={style.buttonContainer}><Link to={slide.link} className="button large">{slide.buttonText}</Link></div>
										</div>
									</div>
								</div>
							</Background>
						</div>
					))}
				</Slider>
			</div>
		)
	}
}

export default Masthead;