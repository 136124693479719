import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
// Import neccessary site components, try to maintain alphabetical order
import Masthead from 'components/masthead';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class Slide extends Component {

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}masthead/slide/all/?_join=true`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						status: STATUS.LOADED,
						data: data,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);

		let initialData = [];
		if (props.data && props.data.allMastheadSlide && props.data.allMastheadSlide.edges.length) {
			props.data.allMastheadSlide.edges.forEach(slide => {
				initialData.push(slide.node);
			});
		}
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}	

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<Masthead data={this.state.data} />
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allMastheadSlide(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	id
			      	title
			      	image
			      	buttonText
			      	link
			      }
			    }
			  }
			}
		`}
		render={data => (<Slide data={data} {...props} />)}
	/>
);