import React, {Component} from 'react'
import Slider from 'react-slick';
import style from './slideshow.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Card from './card';
import Icon from 'components/icon';
import Link from 'shared/link';

class ProviderSlideshow extends Component {
	constructor(props) {
		super(props);
		this.leftArrowClick = this.leftArrowClick.bind(this);
		this.rightArrowClick = this.rightArrowClick.bind(this);
	}

	leftArrowClick() {
		this.slider.slickPrev();
	}

	rightArrowClick() {
		this.slider.slickNext();
	}

	render() {
		const settings = {
			arrows: false,
			dots: false,
			ref: slider => (this.slider = slider),
			slidesToShow: 4,
			infinite: this.data > 3 ? true : false,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1.05,
					}
				}
			],
		};
		return (
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-4">
							<div className={style.title}>{this.props.title ? this.props.title : 'New Providers'}</div>
						</div>
						<div className="cell medium-offset-6 medium-2">
							<div className={style.arrows}>
								<Icon onClick={this.leftArrowClick} className={style.icon} background="fill" icon="leftCircleArrow" />
								<Icon onClick={this.rightArrowClick} className={style.icon} background="fill" icon="rightCircleArrow" />
							</div>
						</div>
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<div className={style.sliderContainer}>
								<Slider {...settings}>
									{this.props.data.map((card, index) => (
										<Card key={index} {...card} />
									))}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProviderSlideshow;