import React, {Component} from 'react'
// Import neccessary site components, try to maintain alphabetical order
import QuickLinks from 'components/quicklinks/';


class QuickLinksController extends Component {
	constructor(props) {
		super(props);

		this.data = [
			{
				title: 'I’m Sick, <br />What Now?',
				intro: 'Recognizing medical symptoms and taking the proper care path can help you recover faster and even save your life. We’ve provided care recommendations for some common symptoms and diagnoses to help you understand when to call your doctor or go to the ER.',
				cta: 'View Symptoms & Diagnoses',
				link: '/im-sick-what-now/',
			},
			{
				title: 'Accepted Insurance',
				intro: 'Click below to view accepted insurance providers and plans.',
				cta: 'View Accepted Plans',
				link: '/patient-info/insurance-info/',
			},
			{
				title: 'Patient \nPortal',
				intro: 'Log in to your FollowMyHealth account to request appointments and refills, view lab and test results and message with your provider.',
				cta: 'Sign In',
				link: 'https://heritagemedical.followmyhealth.com/Login/Home/Index?authproviders=0&returnArea=PatientAccess#!/default',
			},
		]
	}	

	render() {
		return (
			<QuickLinks data={this.data}/>
		);
	}
}

export default QuickLinksController;