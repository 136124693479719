import React, {Component} from 'react'
import style from './specialtygrid.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Button from 'components/button';
import Icon from 'components/icon';
import Link from 'shared/link';

class SpecialtyGrid extends Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}	

	render() {
		return (
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<div className={style.flexContainer}>
								<div className={style.womensCenter}>
									<div className={style.title}>Heritage Women's Center</div>
									<p className={style.intro}>The experienced physicians at Heritage Women’s Center provide exceptional care to women in all phases of life.</p>
									<p className={style.subTitle}>Explore Our Specialties</p>
									<ul className={style.links}>
										<li><Link className={style.link} to="/specialties/obstetrics/">Obstetrics/Pregnancy<Icon className={style.icon} icon="rightArrow"/></Link></li>
										<li><Link className={style.link} to="/specialties/gynecology/">Gynecology<Icon className={style.icon} icon="rightArrow"/></Link></li>
										<li><Link className={style.link} to="/specialties/urogynecology/">Urogynecology<Icon className={style.icon} icon="rightArrow"/></Link></li>
									</ul>
									<div className={style.learnmore}><Button to="/departments/heritage-womens-center/" type="cta">Learn More</Button></div>
								</div>
								<div className={style.subFlexContainer}>
									<div className={style.pediatrics}>
										<div className={style.title}>Pediatrics</div>
										<p className={style.intro}>Our pediatricians, nurses and staff work closely with children and their families to optimize each child’s physical, mental and emotional well-being throughout all stages of development.</p>
										<div className={style.learnmore}><Button to="/specialties/pediatrics/" type="cta white">Learn More</Button></div>
									</div>
									<div className={style.primaryCare}>
										<div className={style.title}>Primary Care</div>
										<p className={style.intro}>We’re devoted to promoting health at every age, coordinating care to elevate wellness, address medical issues, and provide resources to support patients from day one.</p>
										<div className={style.learnmore}><Button to="/specialties/primary-care/" type="cta white">Learn More</Button></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SpecialtyGrid;


/*
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-6">
							<div className={style.womensCenter}>
								<div className={style.title}>Heritage Women's Center</div>
								<p className={style.intro}>The Heritage Women’s Center at Heritage Medical Associates strives to help women care for themselves through all phases of life.  Our goal is to build partnerships with patients and families in the delivery of healthcare services to our communities and to consistently exceed patient expectations in the delivery of effective, quality patient care.</p>
								<ul className={style.links}>
									<li><Link className={style.link} to="/">Obstetrics/Pregnancy<Icon className={style.icon} icon="rightArrow"/></Link></li>
									<li><Link className={style.link} to="/">Gyncecology<Icon className={style.icon} icon="rightArrow"/></Link></li>
									<li><Link className={style.link} to="/">Urogynecology<Icon className={style.icon} icon="rightArrow"/></Link></li>
									<li><Link className={style.link} to="/">Women's Resources<Icon className={style.icon} icon="rightArrow"/></Link></li>
									<li><Link className={style.link} to="/">Patient Testimonials<Icon className={style.icon} icon="rightArrow"/></Link></li>
								</ul>
								<div className={style.learnmore}><Button to="/" type="cta">Learn More</Button></div>
							</div>
						</div>
						<div className="cell small-6 medium-6" style={{height: '100%'}}>
							<div className={style.pediatrics}>
								<div className={style.title}>Pediatrics</div>
								<p className={style.intro}>Our pediatricians and their staff are dedicated to working closely with families and their children as a team, offering not only health care services but also support and education as families face the challenges of caring for their children.</p>
								<div className={style.learnmore}><Button to="/" type="cta white">Learn More</Button></div>
							</div>
							<div className={style.primaryCare}>
								<div className={style.title}>Primary Care</div>
								<p className={style.intro}>Our doctors focus on the whole patient, both physically and emotionally, and coordinate their care to promote health, disease prevention, mental health and an active and fulfilling life.</p>
								<div className={style.learnmore}><Button to="/" type="cta white">Learn More</Button></div>
							</div>
						</div>
					</div>
				</div>
			</div>



						<div className="cell small-6 medium-offset-6 medium-6">
							<div className={style.pediatrics}>
								<div className={style.title}>Primary Care</div>
								<p className={style.intro}>Our doctors focus on the whole patient, both physically and emotionally, and coordinate their care to promote health, disease prevention, mental health and an active and fulfilling life.</p>
								<div className={style.learnmore}><Button to="/" type="cta">Learn More</Button></div>
							</div>
						</div>

 */