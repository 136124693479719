import React, {Component} from 'react'
import style from './quicklinks.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Card from './card';
import Link from 'shared/link';

class QuickLinks extends Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}	

	render() {
		// let maxLength = 0;
		// this.data.forEach((item) => {
		// 	maxLength = Math.max(maxLength, item.title.length);
		// });
		// maxLength += 3;
		// this.data.map(item => (
		// 	item.title += '&nbsp;'.repeat(maxLength-item.title.length)
		// ));

		return (
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						{this.data.map((card, index) => (
							<div key={index} className={[style.card, "cell small-12 medium-4"].join(' ')}>
								<Card {...card} />
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}
}

export default QuickLinks;