import React, { Component } from "react"
import { graphql } from 'gatsby'
// import $ from 'jquery';
// import { Router } from "@reach/router"
import About from 'components/about';
import Layout from 'components/layout/basic';
import MastheadSlideController from 'controllers/masthead/slide';
import NewProviderController from 'controllers/providers/newprovider';
import PatientEducationController from 'controllers/blog/patienteducation';
import QuickLinksController from 'controllers/quicklinks/card';
import SEO from 'shared/seo';
import SpecialtyGrid from 'components/specialtygrid';

export default class IndexPage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<Layout>
					<MastheadSlideController />
					<QuickLinksController />
					<NewProviderController />
					<SpecialtyGrid />
					<PatientEducationController />
					<hr />
					<About />
					<SEO />
				</Layout>
			</>
		)
	}
}

/*
export const query = graphql`
	query MyQuery {
	  allMastheadSlide(filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}) {
	    edges {
	      node {
	        title
	        description
	        image
	        shareImage
	        shareTitle
	        shareDescription
	        Category {
	          object {
	            name
	          }
	        }
	        sort
	      }
	    }
	  }
	}
`
*/