import React, {Component} from 'react'
import style from './card.module.scss';
import parse from 'html-react-parser';
// Import neccessary site components, try to maintain alphabetical order
import Button from 'components/button';
import Link from 'shared/link';

class Card extends Component {
	constructor(props) {
		super(props);
	}	

	render() {
		const { title, intro, cta, link } = this.props;
		return (
			<div className={style.card}>
				<div className={style.title}>{parse(title)}</div>
				<div className={style.intro}>{intro}</div>
				<div className={style.cta}><Button to={link} type="cta white">{cta}</Button></div>
			</div>
		)
	}
}

export default Card;