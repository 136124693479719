import React from 'react';
import style from './about.module.scss';
import Button from 'components/button';
import Link from 'shared/link';

export default (props) => (
	<div className={style.container}>
		<div className="grid-container">
			<div className={[style.header, "grid-x grid-margin-x"].join(' ')}>
				<div className="cell medium-6 small-12">
					<div className={style.title}>About Heritage Medical Associates</div>
					<p className={style.intro}>Led by physicians, Heritage Medical Associates takes a patient-centered approach to providing the most advanced, high-quality care available. Our board-certified physicians and highly trained staff of nurses and support personnel ensure each patient is treated as an individual, delivering comprehensive care with respect and compassion.</p>
					<p><Button to="/about/" type="cta">Learn More</Button></p>
				</div>
				<div className="cell medium-offset-2 medium-4 small-6 small-offset-3">
					<div className={style.logoContainer}>
						<img src="/hma-circle.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div className={style.spacer}/>
		<div className="grid-container">
			<div className="grid-x grid-margin-x">
				<div className={[style.ctaButton, "cell small-12 medium-4 columns"].join(' ')}>
					<Button type="fullWidth secondary large" to="/about/leadership/">Leadership</Button>
				</div>
				<div className={[style.ctaButton, "cell small-12 medium-4 columns"].join(' ')}>
					<Button type="fullWidth secondary large" to="/contact-us/">Contact Us</Button>
				</div>
				<div className={[style.ctaButton, "cell small-12 medium-4 columns"].join(' ')}>
					<Button type="fullWidth secondary large" to="/careers/">Careers</Button>
				</div>
			</div>
		</div>
	</div>
)