import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby';
// Import neccessary site components, try to maintain alphabetical order
import Slideshow from 'components/patienteducation/slideshow';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class PatientEducationController extends Component {

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}blog/post/all/?Topic.id=606b6690706673111332a4e6`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let posts = this.processTags(data);
					this.setState({
						status: STATUS.LOADED,
						data: posts,
					})
				}
			})
			.catch(err => console.log);
	}

	processTags(data) {
		function removeDuplicateObjectFromArray(array, key) {
			return array.filter((obj, index, self) =>
				index === self.findIndex((el) => (
					el[key] === obj[key]
				))
			)
		}
		if (!this.props.tags) {
			return data;
		} else if (this.props.tags) {
			let posts = [];
			let type = typeof this.props.tags;
			switch(type) {
				case 'string':
					let tag = this.props.tags;
					data.forEach(post => {
						if (post.tags && post.tags.includes(tag)) {
							posts.push(post);
						}
					})
					break;
				case 'object':
					this.props.tags.forEach(tag => {
						data.forEach(post => {
							if (post.tags && post.tags.includes(tag)) {
								posts.push(post);
							}
						})
					})
					break;
				default:
					console.log('I dont know');
			}
			if (posts.length > 0) {
				posts = removeDuplicateObjectFromArray(posts, 'uri');
				return posts;
			} else {
				return posts;
			}
		} else {
			return data;
		}
	}

	constructor(props) {
		super(props);
		let initialData = [];
		if (props.data && props.data.allBlogPost && props.data.allBlogPost.edges.length) {
			props.data.allBlogPost.edges.forEach(alert => {
				initialData.push(alert.node);
			});
		}
		let processedData = this.processTags(initialData);
		this.state = {
			status: STATUS.INITIAL,
			data: processedData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.processTags = this.processTags.bind(this);
	}	

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		if (!this.state.data.length) {
			return true;
		} else {
			return (
				<Slideshow data={this.state.data} />
			);
		}
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allBlogPost(filter: {Topic: {elemMatch: {object: {id: {eq: "606b6690706673111332a4e6"}}}}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	title
			      	photo
			      	intro
			      	uri
			      	tags
			      }
			    }
			  }
			}
		`}
		render={data => (<PatientEducationController data={data} {...props} />)}
	/>
)