import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby';
import { buildURL } from 'react-imgix';
import styles from './background.module.scss';
// import ClientOnly from './ClientOnly';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';

if (typeof document !== 'undefined' && window.__lazyBGLOADINIT === undefined) {
	const bgLoad = function (url, cb){
		var img = document.createElement('img');
		img.onload = function(){
			img.onload = null;
			img.onerror = null;
			img = null;
			cb();
		};
		img.onerror = img.onload;
		img.src = url.substring(url.indexOf("'") + 1, url.lastIndexOf("'"));
		if(img && img.complete && img.onload){
			img.onload();
		}
	};
	document.addEventListener('lazybeforeunveil', function(evt){
		const target = evt.target;
		const background = target.getAttribute('data-background');
		if (background) {
			evt.detail.firesLoad = true;
			const load = (function(){
				return function(){
					target.style.backgroundImage = background;
					evt.detail.firesLoad = false;
					window.lazySizes.fire(target, '_lazyloaded', {}, true, true);
				}
			})(background);
			bgLoad(background, load);
		}
	});
	window.__lazyBGLOADINIT = true;
}

class Background extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
		}
	}

	render() {
		if (typeof window === 'undefined' && this.props.clientOnly === true)return null;
		let { image, width, overlay, className, lowQuality, remoteImage, crop, ar } = this.props;
		let domain = this.props.data.site.siteMetadata.imgCDN;
		if (lowQuality === undefined)lowQuality = true;
		let imagePath = (image ? image.replace(/^\//, '') : 'images/missing.jpg');
		let lowQualityImage;
		if (imagePath.indexOf('/') === -1)imagePath = `images/${imagePath}`;
		if (remoteImage === undefined) imagePath = `${domain}/${imagePath}`;
		width = width||1920;
		imagePath = buildURL(imagePath, {
			'w': width,
			auto: 'compress',
		});
		if (crop) {
			imagePath = `${imagePath}&fit=crop&crop=${crop}`;
		}
		if (ar) {
			imagePath = `${imagePath}&ar=${ar}`;
		}
		if (lowQuality === true) {
			lowQualityImage = decodeURI(imagePath);
			lowQualityImage = buildURL(lowQualityImage, {
				w: width*.05,
				blur: 20,
			});
			lowQualityImage = `url('${lowQualityImage}')`;
		}
		imagePath = `url('${imagePath}')`;
		if (overlay) {
			imagePath = `${overlay}, ${imagePath}`;
			if (lowQuality) {
				lowQualityImage = `${overlay}, ${lowQualityImage}`;
			}
		}
		className = (className||'').split(' ');
		className.push(styles.container);
		if (lowQuality) {
			className.push('lazyload');
		}
		className = className.filter((item) => item);
		if (remoteImage === true) imagePath = image;
		let passThrough = Object.assign({}, this.props);
		let style = Object.assign({}, passThrough.style, {
			width: '100%',
			minHeight: '100%',
			opacity: '.99',
			position: 'relative',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundImage: imagePath,
		});
		if (lowQuality) {
			style.backgroundImage = lowQualityImage;
			style['--lowquality'] = lowQualityImage;
			passThrough['data-background'] = imagePath;
		}
		delete passThrough.data;
		delete passThrough.image;
		delete passThrough.width;
		delete passThrough.overlay;
		delete passThrough.children;
		delete passThrough.style;
		delete passThrough.className;
		delete passThrough.lowQuality;
		delete passThrough.clientOnly;
		return (
			<>
				<div
					style={style}
					className={className.join(' ')}
					{...passThrough}
				 >{this.props.children}</div>
			</>
		)
	}
}

export default (props) => (
	<StaticQuery 
		query={graphql`
			query {
				site {
					siteMetadata {
						imgCDN
					}
				}
			}
		`}
		render={(data) => (
			<Background data={data} {...props} />
		)}
	/>
);