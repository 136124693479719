import React, {Component, Fragment} from 'react'
import { StaticQuery, graphql } from 'gatsby'
// Import neccessary site components, try to maintain alphabetical order
import ProvidersSlideshow from 'components/providers/slideshow';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class NewProviderController extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/provider/all/?newPhysician=true&_join=Category`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let physicians = [];
					data.forEach(physician => {
						physicians.push(this.processData(physician));
					});
					this.setState({
						status: STATUS.LOADED,
						data: physicians,
					});
					console.log('Live Refresh Complete');
				}
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			firstName: data.firstName,
			lastName: data.lastName,
			maidenName: data.maidenName,
			middleName: data.middleName,
			nickName: data.nickName,
			title: data.abbreviations.toString(),
			category: data.Category[0].object.name,
			specialty: null,
			cta: 'Visit Profile',
			photo: data.photo,
			link: data.uri,
		}

		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			data.Specialty.forEach(specialty => {
				if (specialty.object.id) {
					this.specialties.forEach(item => {
						if (item.node.id === specialty.object.id) {
							specialties.push(item.node.name);
						}
					});
				}
			});
			if (specialties.length) {
				newDatum.specialty = specialties;
			} else {
				newDatum.specialty = null;
			}
		}
		return newDatum;
	}

	constructor(props) {
		super(props);

		let initialData = [];
		this.specialties = (props.data && props.data.allPhysiciansSpecialty && props.data.allPhysiciansSpecialty.edges.length ? props.data.allPhysiciansSpecialty.edges : null);
		if (props.data && props.data.allPhysiciansProvider && props.data.allPhysiciansProvider.edges.length) {
			props.data.allPhysiciansProvider.edges.forEach(physician => {
				initialData.push(this.processData(physician.node));
			});
		}

		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);

		this.processData = this.processData.bind(this);
	}

	componentDidMount() {
		// this.liveRefresh();
	}

	render() {
		if (!this.state.data.length) {
			return true;
		} else {
			return (
				<Fragment>
					<hr />
					<ProvidersSlideshow data={this.state.data} />
				</Fragment>
			);
		}
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allPhysiciansProvider(sort: {order: DESC, fields: showDate}, filter: {newPhysician: {eq: true}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	id
			      	firstName
			      	lastName
			      	maidenName
			      	middleName
			      	nickName
			      	photo
			      	namePrefix
			      	abbreviations
			      	uri
			      	groupStartDate
			      	Category {
			          object {
			          	name
			            id
			          }
			        }
			      	Specialty {
			          object {
			            id
			          }
			        }
			      }
			    }
			  }
			  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			        id
			        name
			      }
			    }
			  }
			}
		`}
		render={data => (<NewProviderController data={data} {...props} />)}
	/>
)