import React, {Component} from 'react'
import style from './card.module.scss';
import parse from 'html-react-parser';
// Import neccessary site components, try to maintain alphabetical order
import Background from 'shared/background';
import Button from 'components/button';

class Card extends Component {
	constructor(props) {
		super(props);
	}	

	render() {
		const { title, intro, photo, cta, uri } = this.props;
		return (
			<div className={style.container}>
				<div className={ [style.card, (photo === null ? style.noPhoto : '')].join(' ') }>
					{photo && <div className={style.photoFrame}><Background className={style.photo} image={photo}/></div>}
					<div className={style.content}>
						<div className={style.title}>{title}</div>
						<div className={style.intro}>{intro}</div>
						<div className={style.cta}><Button to={uri} type="cta">{cta ? cta : 'Learn More'}</Button></div>
					</div>
				</div>
			</div>
		)
	}
}

export default Card;