import React, {Component} from 'react'
import style from './card.module.scss';
import parse from 'html-react-parser';
// Import neccessary site components, try to maintain alphabetical order
import Background from 'shared/background';
import Button from 'components/button';
import Link from 'shared/link';

class Card extends Component {
	constructor(props) {
		super(props);
	}	

	render() {
		var { firstName, lastName, middleName, nickName, maidenName, title, cta, link, photo, specialty, category } = this.props;
		if (!photo) {
			photo = '/userfiles/dr-blank.jpg';
		}
		return (
			<div className={style.card}>
				<Link to={link}>
					<div className={style.photoFrame}><Background crop="faces,center" ar="1:1.2" width={800} className={style.photo} image={photo}/></div>
					<div className={style.content}>
						<div className={style.name}>{firstName}{middleName ? ` ${middleName} ` : ' '}{nickName ? ` "${nickName}" ` : ' '}{lastName}, {title}</div>
						<div className={style.specialty}>{category ? `${category} - ` : ''}{specialty ? specialty.join(', ') : ''}</div>
						<div className={style.cta}><Button to={link} type="cta">{cta}</Button></div>
					</div>
				</Link>
			</div>
		)
	}
}

export default Card;

/*
import React, {Component} from 'react'
import style from './card.module.scss';
import parse from 'html-react-parser';
// Import neccessary site components, try to maintain alphabetical order
import Link from 'shared/link';

class Card extends Component {
	constructor(props) {
		super(props);
	}	

	render() {
		const { title, intro, cta, link } = this.props;
		return (
			<div className={style.card}>
				<div className={style.title}>{parse(title)}</div>
				<div className={style.intro}>{intro}</div>
				<div className={style.cta}><Link to={link}>{cta}</Link></div>
			</div>
		)
	}
}

export default Card;
 */