import React, {Component} from 'react'
import Slider from 'react-slick';
import style from './slideshow.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Card from './card';
import Icon from 'components/icon';
import Link from 'shared/link';

class PatientEducationSlideshow extends Component {
	constructor(props) {
		super(props);
		this.data = props.data;
		this.leftArrowClick = this.leftArrowClick.bind(this);
		this.rightArrowClick = this.rightArrowClick.bind(this);
		this.slider = null;
	}

	leftArrowClick() {
		if (this.slider)this.slider.slickPrev();
	}

	rightArrowClick() {
		if (this.slider)this.slider.slickNext();
	}

	render() {
		const settings = {
			arrows: false,
			dots: false,
			ref: slider => (this.slider = slider),
			slidesToShow: 4,
			infinite: false,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1.05,
					}
				}
			],
		};
		return (
			<div className={style.container}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-4">
							<div className={style.title}>Patient Education</div>
							<div className={style.more}><Link to="/topic/patient-education/">See All</Link></div>
						</div>
						<div className="cell medium-offset-6 medium-2">
							<div className={[style.arrows, (this.data.length < 2 ? 'hide' : ''), (this.data.length > 3 ? 'show-for-medium' : '')].join(' ')}>
								<Icon onClick={this.leftArrowClick} className={style.icon} icon="leftCircleArrow" />
								<Icon onClick={this.rightArrowClick} className={style.icon} icon="rightCircleArrow" />
							</div>
						</div>
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<div className={style.sliderContainer}>
								<Slider {...settings}>
									{this.data.map((card, index) => (
										<Card key={index} {...card} />
									))}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PatientEducationSlideshow;