import React from 'react';
import style from './button.module.scss';
import Icon from 'components/icon';
import Link from 'shared/link';

export default (props) => {
	let classes = [style.button];
	const types = (typeof props.type === 'string' ? props.type.split(' ') : props.type);
	types.forEach(type => {
		classes.push(style[type]);
	});
	return (
		<Link to={props.to} className={ classes.join(' ') }>{props.children}{ types.indexOf('cta') > -1 && <Icon className={style.icon} icon="rightArrow"/> }</Link>
	)
}
